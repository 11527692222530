export function oidcSettings() {
  const settings = {
    authority: process.env.VUE_APP_OIDC_AUTHORITY,
    client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_OIDC_REDIRECT_URI,
    response_type: process.env.VUE_APP_OIDC_RESPONSE_TYPE,
    scope: process.env.VUE_APP_OIDC_SCOPE,
    post_logout_redirect_uri: process.env.VUE_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
    automaticSilentRenew:
      process.env.VUE_APP_OIDC_AUTOMATIC_SILENT_RENEW === 'true' ? true : false,
    silent_redirect_uri: process.env.VUE_APP_OIDC_SILENT_REDIRECT_URI,
    accessTokenExpiringNotificationTime: Number.parseInt(
      process.env.VUE_APP_OIDC_ACCESS_TOKEN_NOTIFICATION_TIME
    )
  };
  return settings;
}

///publish oidc-settings
// export const oidcSettings = {
//   authority: 'https://teknisk-industrivern-identity.sys02ssl.avento.no',
//   client_id: 'tiClient',
//   redirect_uri: 'https://teknisk-industrivern.sys02ssl.avento.no/oidc-callback',
//   response_type: 'id_token token',
//   scope: 'openid profile tiApi',
//   post_logout_redirect_uri: 'https://teknisk-industrivern.sys02ssl.avento.no/',
//   automaticSilentRenew: true,
//   silent_redirect_uri:
//     'https://teknisk-industrivern.sys02ssl.avento.no/silent-renew-oidc.html',
//   accessTokenExpiringNotificationTime: 10
// };

///local oidc-settings
// export const oidcSettings = {
//   authority: 'https://localhost:5555',
//   client_id: 'tiClient',
//   redirect_uri: 'https://localhost:5000/oidc-callback',
//   response_type: 'id_token token',
//   scope: 'openid profile tiApi',
//   post_logout_redirect_uri: 'https://localhost:5000/',
//   automaticSilentRenew: true,
//   silent_redirect_uri: 'https://localhost:5000/silent-renew-oidc.html',
//   accessTokenExpiringNotificationTime: 10
// };
