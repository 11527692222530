import Vue from 'vue';
import Vuex from 'vuex';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import { oidcSettings } from '@/app/auth/config';

Vue.use(Vuex);

const settings = oidcSettings();

const store = new Vuex.Store({
  modules: {
    oidcStore: vuexOidcCreateStoreModule(settings, {
      namespaced: true,
      dispatchEventsOnWindow: true
    })
  },
  state: {
    breadcrumbs: [],
    building: null
  },
  mutations: {
    changeBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
    setBuilding(state, building) {
      state.building = building;
    }
  }
});

export default store;