import Vue from "vue";
import Router from "vue-router";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import store from "@/store";
import i18n from "@/i18n";
import { contains } from "ramda";

import authRoutes from "@/app/auth/routes";
import guestRoutes from "@/app/guest/routes";
import userRoutes from "@/app/users/routes";
import companyRoutes from "@/app/companies/routes";
import participantRoutes from "@/app/participant/routes";
import buildingRoutes from "@/app/buildings/routes";
import departmentRoutes from "@/app/departments/routes";
import profileRoutes from "@/app/profile/routes";
import courseRoutes from "@/app/courses/routes";
import courseModuleRoutes from "@/app/coursemodules/routes";
import lessonRoutes from "@/app/lessons/routes";
import myCoursesRoutes from "@/app/myCourses/routes";
import adminRoutes from "@/app/admin/routes";
import resourcesRoutes from "@/app/resources/routes";
import courseEventRoutes from "@/app/courseevents/routes";

Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/no",
  },
  {
    path: "/:lang",
    component: { render: (h) => h("router-view") },
    beforeEnter: (to, from, next) => {
      if (!contains(to.params.lang, ["no", "en"])) {
        return next("/no");
      }

      if (i18n.locale === to.params.lang) {
        return next();
      }

      i18n.locale = to.params.lang;
      return next();
    },
    children: [
      {
        path: "/",
        component: () => import("@/layouts/Guest.vue"),
        meta: {
          isPublic: true,
        },
        children: [...guestRoutes],
      },
      {
        path: "participate",
        component: () => import("@/layouts/Participate.vue"),
        meta: {
          isPublic: true,
        },
        children: [...participantRoutes],
      },
      {
        path: "app",
        component: () => import("@/layouts/App.vue"),
        children: [
          ...userRoutes,
          ...companyRoutes,
          ...buildingRoutes,
          ...departmentRoutes,
          ...profileRoutes,
          ...courseRoutes,
          ...courseModuleRoutes,
          ...lessonRoutes,
          ...myCoursesRoutes,
          ...adminRoutes,
          ...resourcesRoutes,
          ...courseEventRoutes,
        ],
      },
    ],
  },
  ...authRoutes,
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
export default router;
