export default [
  {
    path: 'my-courses',
    component: () => import('../views/MyCourses'),
    children: [
      {
        path: '/',
        name: 'my-courses-index',
        component: () => import('../views/Index')
      },
      {
        path: ':mcid/show',
        name: 'my-course-show',
        component: () => import('../views/MyCourse')
      },
      {
        path: ':mcid/exam',
        name: 'my-course-exam',
        component: () => import('../views/Exam')
      }
    ]
  }
];
