export default [
  {
    path: '/oidc-callback', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcCallback',
    component: () => import('../views/OidcCallback.vue'),
    meta: {
      isOidcCallback: true,
      isPublic: true
    }
  },
  {
    path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcCallbackError',
    component: () => import('../views/OidcCallbackError.vue'),
    meta: {
      isPublic: true
    }
  }
];
