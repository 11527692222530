export default [
  {
    path: 'departments',
    component: () => import('../views/Departments'),
    children: [
      {
        path: '/',
        name: 'departments-index',
        component: () => import('../views/Index')
      },
      {
        path: 'create',
        name: 'departments-create',
        component: () => import('../views/Create')
      },
      {
        path: ':id/edit',
        name: 'departments-edit',
        component: () => import('../views/Edit')
      },
      {
        path: ':id/users',
        name: 'departments-users',
        component: () => import('../views/DepartmentsUsers')
      },
      {
        path: ':id/users-create',
        name: 'departments-users-create',
        component: () => import('../views/DepartmentUserCreate')
      },
      {
        path: ':id/show',
        name: 'department-show',
        component: () => import('../views/DepartmentShow')
      },
      {
        path: ':id/show/:cid/inspect',
        name: 'inspect-department-course',
        component: () => import('../views/InspectDepartmentCourse')
      }
    ]
  }
];
